<template>
  <div>
    <section class="section">
      <h1 class="title is-2">{{ $t('system.spaces') }} </h1>
      <div class="cardList">
        <div class="card has-background-light" v-for="room in playlist.filter(room => HasScreen(room.experience))" :key="room.space.id">
          <div class="card-image">
          </div>
          <div class="card-content">
            <div class="media mb-0">
              <div class="media-content">
                <div class="columns">
                  <!-- title -->
                  <p class="title is-4" style="flex:1">{{ room.space.title }}</p>
                  <!-- component tag -->
                  <b-tag type="is-primary" size="is-small" v-if="room.experience.component">
                    {{ $camelToWords(room.experience.component[0].blockType) }}
                  </b-tag>
                </div>
                <p class="subtitle is-5">{{ room.experience.title }}</p>
              </div>
            </div>

            <div class="content">
              {{ room.experience.description }}
              <br />
              <h6 class="subtitle is-6 mb-0" v-if="room.experience.category">{{ room.experience.category.title }}</h6>
            </div>
          </div>
          <footer class="card-footer">
            <a @click="SetDefaultSpace(room.space.slug)" class="card-footer-item  has-text-centered">{{
                $t('system.setDefaultAndEnter')
            }} </a>
            <router-link :to="'/spaces/' + room.space.slug"
              class="card-footer-item has-text-centered is-button is-primary is-light">{{ $t('system.enter') }}
            </router-link>
          </footer>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import router from "../router";

export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    SetDefaultSpace(space) {
      localStorage.setItem("defaultSpace", space);
      router.push("/spaces/" + space);
    },
    HasScreen(exp) {
      const component = exp.component[0].blockType
      const componensWithouScreen = ["survey","infoSpot","puzzle"]
      if (componensWithouScreen.includes(component)) {
        return false
      }
      return true;
    }
  },
  computed: {
    playlist() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.getters.playlist
        .filter(x => x.experience)
        .sort(function (a, b) {
          if (b.space.section.order > a.space.section.order) {
            return -1;
          }
          if (b.space.section.order < a.space.section.order) {
            return 1;
          }
          return 0;
        })
    }
  },
  mounted() {
    this.$store.commit("SetdisableReconnectingAlert", true);
    const defaultSpace = localStorage.getItem("defaultSpace");
    if (defaultSpace) {
      router.push("/spaces/" + defaultSpace);
    }else{
      this.$store.commit("StopLoading")
    }
    
  },
};
</script>
<style scoped>
.cardList {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  display: grid;
  grid-gap: 30px;
}
</style>
